.hourglass,
.hourglass * {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.hourglass {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.hourglass .hourglass-loader-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: var(--cl);
    font-weight: 700;
    line-height: 1.7;
}

.hourglass .hourglass-loader-items {
    width: 100%;
    height: 100px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-zoom: var(--size);
    zoom: var(--size);
}

.hourglass .hourglass-loader-items .hourglass-item {
    border: 30px solid transparent;
    border-top-color: var(--bg);
    border-bottom-color: var(--bg);
    border-radius: 50%;
    -webkit-animation: hourglass 1.2s ease-in infinite;
    animation: hourglass 1.2s ease-in infinite;
}

@-webkit-keyframes hourglass {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    80% {
        -webkit-transform: rotate(900deg);
        transform: rotate(900deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes hourglass {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    45% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    80% {
        -webkit-transform: rotate(900deg);
        transform: rotate(900deg);
    }
    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}